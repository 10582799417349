/*!
Pure v2.1.0
Copyright 2013 Yahoo!
Licensed under the BSD License.
https://github.com/pure-css/pure/blob/master/LICENSE
*/

[class^='quiqly-'],
[class*='quiqly-'] {
  display: flex;
  flex-direction: column;
  letter-spacing: normal;
  word-spacing: normal;
  text-rendering: auto;
}
.quiqly-u {
  width: 100%;
}

@media screen and (min-width: 35.5em) {
  .quiqly-sm-1.quiqly-sm-1-1 {
    width: 100%;
  }
  .quiqly-sm-1-2 {
    width: 50%;
  }
  .quiqly-sm-1-3 {
    width: 33.33%;
  }
  .quiqly-sm-2-3 {
    width: 66.67%;
  }
  .quiqly-sm-1-4 {
    width: 25%;
  }
  .quiqly-sm-3-4 {
    width: 75%;
  }
  .quiqly-sm-1-5 {
    width: 20%;
  }
  .quiqly-sm-2-5 {
    width: 40%;
  }
  .quiqly-sm-3-5 {
    width: 60%;
  }
  .quiqly-sm-4-5 {
    width: 80%;
  }
  .quiqly-sm-1-6 {
    width: 16.67%;
  }
  .quiqly-sm-1-7 {
    width: 14.29%;
  }
  .quiqly-sm-1-8 {
    width: 12.5%;
  }
  .quiqly-sm-1-9 {
    width: 11.11%;
  }
  .quiqly-sm-1-10 {
    width: 10%;
  }
  .quiqly-sm-1-11 {
    width: 9.09%;
  }
  .quiqly-sm-1-12 {
    width: 8.33%;
  }
}

@media screen and (min-width: 48em) {
  .quiqly-md-1.quiqly-md-1-1 {
    width: 100%;
  }
  .quiqly-md-1-2 {
    width: 50%;
  }
  .quiqly-md-1-3 {
    width: 33.33%;
  }
  .quiqly-md-1-4 {
    width: 25%;
  }
  .quiqly-md-3-4 {
    width: 75%;
  }
  .quiqly-md-1-5 {
    width: 20%;
  }
  .quiqly-md-2-5 {
    width: 40%;
  }
  .quiqly-md-3-5 {
    width: 60%;
  }
  .quiqly-md-4-5 {
    width: 80%;
  }
  .quiqly-md-1-6 {
    width: 16.67%;
  }
  .quiqly-md-1-7 {
    width: 14.29%;
  }
  .quiqly-md-1-8 {
    width: 12.5%;
  }
  .quiqly-md-1-9 {
    width: 11.11%;
  }
  .quiqly-md-1-10 {
    width: 10%;
  }
  .quiqly-md-1-11 {
    width: 9.09%;
  }
  .quiqly-md-1-12 {
    width: 8.33%;
  }
}

@media screen and (min-width: 64em) {
  .quiqly-lg-1.quiqly-lg-1-1 {
    width: 100%;
  }
  .quiqly-lg-1-2 {
    width: 50%;
  }
  .quiqly-lg-1-3 {
    width: 33.33%;
  }
  .quiqly-lg-1-4 {
    width: 25%;
  }
  .quiqly-lg-3-4 {
    width: 75%;
  }
  .quiqly-lg-1-5 {
    width: 20%;
  }
  .quiqly-lg-2-5 {
    width: 40%;
  }
  .quiqly-lg-3-5 {
    width: 60%;
  }
  .quiqly-lg-4-5 {
    width: 80%;
  }
  .quiqly-lg-1-6 {
    width: 16.67%;
  }
  .quiqly-lg-1-7 {
    width: 14.29%;
  }
  .quiqly-lg-1-8 {
    width: 12.5%;
  }
  .quiqly-lg-1-9 {
    width: 11.11%;
  }
  .quiqly-lg-1-10 {
    width: 10%;
  }
  .quiqly-lg-1-11 {
    width: 9.09%;
  }
  .quiqly-lg-1-12 {
    width: 8.33%;
  }
}

@media screen and (min-width: 80em) {
  .quiqly-xl-1.quiqly-xl-1-1 {
    width: 100%;
  }
  .quiqly-xl-1-2 {
    width: 50%;
  }
  .quiqly-xl-1-3 {
    width: 33.33%;
  }
  .quiqly-xl-1-4 {
    width: 25%;
  }
  .quiqly-xl-3-4 {
    width: 75%;
  }
  .quiqly-xl-1-5 {
    width: 20%;
  }
  .quiqly-xl-2-5 {
    width: 40%;
  }
  .quiqly-xl-3-5 {
    width: 60%;
  }
  .quiqly-xl-4-5 {
    width: 80%;
  }
  .quiqly-xl-1-6 {
    width: 16.67%;
  }
  .quiqly-xl-1-7 {
    width: 14.29%;
  }
  .quiqly-xl-1-8 {
    width: 12.5%;
  }
  .quiqly-xl-1-9 {
    width: 11.11%;
  }
  .quiqly-xl-1-10 {
    width: 10%;
  }
  .quiqly-xl-1-11 {
    width: 9.09%;
  }
  .quiqly-xl-1-12 {
    width: 8.33%;
  }
}

@media screen and (min-width: 120em) {
  .quiqly-xxl-1.quiqly-xxl-1-1 {
    width: 100%;
  }
  .quiqly-xxl-1-2 {
    width: 50%;
  }
  .quiqly-xxl-1-3 {
    width: 33.33%;
  }
  .quiqly-xxl-1-4 {
    width: 25%;
  }
  .quiqly-xxl-3-4 {
    width: 75%;
  }
  .quiqly-xxl-1-5 {
    width: 20%;
  }
  .quiqly-xxl-2-5 {
    width: 40%;
  }
  .quiqly-xxl-3-5 {
    width: 60%;
  }
  .quiqly-xxl-4-5 {
    width: 80%;
  }
  .quiqly-xxl-1-6 {
    width: 16.67%;
  }
  .quiqly-xxl-1-7 {
    width: 14.29%;
  }
  .quiqly-xxl-1-8 {
    width: 12.5%;
  }
  .quiqly-xxl-1-9 {
    width: 11.11%;
  }
  .quiqly-xxl-1-10 {
    width: 10%;
  }
  .quiqly-xxl-1-11 {
    width: 9.09%;
  }
  .quiqly-xxl-1-12 {
    width: 8.33%;
  }
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}
#checkout-container-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

#checkout-container {
  min-width: min(100%, 400px) !important;
  max-width: min(100%, 400px) !important;
  background: rgba(255,255,255,0.5);
  box-shadow: rgba(255,255,255,0.5) 0px 0px 15px 5px;
}

#checkout-container iframe {
  width: 100%;
  /*filter:url(#chromakey);*/
}

/* comaptibility */
*:not(path,svg) {
  color: var(--font-color-strong);
}
.quiqly-mode-popup {
  margin: 0 !important;
}
.quiqly-mode-popup .quiqly-mainContext {
    padding: 0 !important;
}
.quiqly-mode-popup .quiqly-innerContext {
  box-shadow: none !important;
}
.quiqly-mode-popup .header {
  width: 95%;
}


.quiqly-mode-popup body {
  margin:0;
}

html {
  min-height: 100vh;
}