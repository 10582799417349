.mainContainer {
  padding: 3.5em 1em 0 1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.innerContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 16px 24px;
  border-radius: 5px;
  --bg-color: white;
  --bg-image: url(../utils/img/background.png);
  background-color: var(--bg-color);
  background-image: var(--bg-image);
  background-size: cover;
  background-repeat: no-repeat;
  box-shadow: 0 0 0.4rem #AAAAAA;
  width: 100%;
  min-width: 300px;

  min-height: 600px;
  @media (min-width: 700px) {
    width:650px;
  }
}