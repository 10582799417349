@mixin centeredFlex {
  display: flex;
  flex-direction: column;
  /* width: 100%; */
  justify-content: center;
  align-items: center;
}
.componentContainer {
  @include centeredFlex();
  width: 100%;
  max-width: 400px;
  img {
    width: calc(100% - 175px);
  }
}

.headlineTitle {
  @include centeredFlex();
  width: 100%;
  text-align: center;
  color: var(--font-color-strong);

  h2 {
    font-weight: bold;
    font-family: 'ProximaNovaBold';
    margin: 1.5em 0;
  }
  h3 {
    font-weight: lighter;
    margin: 0.5em 0;
  }
  p {
    margin: 0 0 1.5em 0;
    //width: 80%;
    //align-self: center;
  }
}

.inputsWrapper {
  @include centeredFlex();
  width: 100%;
  input {
    width: 100%;
    border-radius: 0px;
    border: none;
    height: 3.5em;
    background-color: white;
    padding-left: 15px;
  }
}
.submitButton {
  margin-top: 1.5em;
  width: 90%;
  height: 3em;
  background: transparent linear-gradient(287deg, #185ADB 0%, #0A1931 100%) 0% 0% no-repeat padding-box;
  color: white;
  border-radius: 5em;
  border: none;
  font-size: 18px;
  cursor: pointer;
}

.proceedButton {
  margin-top: 1em;
  width: 90%;
  height: 3em;
  background: white;
  color: black;
  border-radius: 4px;
  border: 1px solid black;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.extraInfoText {
  color: var(--font-color-strong);
  margin-top: 2.5em;
  span {
    color: var(--font-color-strong);
  }
}
.iconsWrapper {
  @include centeredFlex();
  min-width: 150px;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0.75em;
  }
}
.icons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 0;
  filter: contrast(0) brightness(0);
}

.footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  a {
    font-size: small;
    color: var(--font-color-strong);
  }
  p {
    margin: 0 5px;
    font-size: small;
    color: var(--font-color-strong);
    cursor: pointer;
  }
}
.footerBack {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: small;
  color: var(--font-color-strong);
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 0.5em;
  h1 {
    font-weight: bolder;
    font-family: 'ProximaNovaBold';
    font-size: 1.5em;
    margin: 0;
  }

  p {
    text-decoration: underline;
    color: var(--font-color-strong);
    font-size: small;
    cursor: pointer;
  }
}

.headerLogout {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: small;
  color: black;
  min-width: 8em;
}
.closeButton {
  position: absolute;
  top: -8px;
  right: -8px;
  width: 30px;
  height: 30px;
  border-radius: 5em;
  display: flex;
  justify-content: center;
  background-color: rgb(255, 255, 255);
  color: black !important;
  align-items: center;
  box-shadow: 0 0 0.8rem #AAAAAA;
  cursor: pointer;
}

.logoutButton {
  cursor: pointer;
  p {
    text-decoration: underline;
    color: grey;
    font-size: small;
  }
}

.verify {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height:100%;
  margin-bottom: 0.5em;
  h1 {
    font-weight: bolder;
    font-family: 'ProximaNovaBold';
    font-size: 3em;
    margin: 20px;
  }
}

.Collapsible {
  width: 100%;
  border-bottom: 1px solid black;
  padding-bottom: 12px;
  padding-top: 12px;
  
}
.CollapsibleTrigger {
  font-family: "KantumruyProRegular";
  font-size: 16px;
  position: relative;
  display: block;
  padding-right: 1em;
  cursor: pointer;
  &:after {
    font-size: 25px;
    right: 5px;
    top: -7px;
    content: '‹';
    position: absolute;
    display: block;
    transition: transform 300ms;
    transform:rotate(90deg);
    transform-origin: 50% 55%;
  }
}
.CollapsibleTriggerOpen {
  &:after {
    transform:rotate(270deg);
  }
}

.CollapsibleContent {
  font-family: "KantumruyProRegular";
  font-size: 16px;
}

* {
  --bg-color: white;
  --bg-image: url(../utils/img/background.png);
  --font-color-strong: black;
  --font-color-weak: grey;
}